import React from 'react';
import classes from "./ImgDisplay.module.css";


const ImgDisplay = () => {
  const imageUrl1 = require('../../assets/1.JPG');
  const imageUrl2 = require('../../assets/2.jpeg');
  const imageUrl3 = require('../../assets/3.JPG');

  return (
    <div className={classes.Container}>
      <img className={classes.Img} src={imageUrl1} alt="Civilmart culverts" />
      <img className={classes.Img} src={imageUrl2} alt="Urban Superway" />
      <img className={classes.Img} src={imageUrl3} alt="Sydney Metro" />
    </div>
  );
}

export default ImgDisplay;